/* 
 * global css
 * @version {{version}}
 */

// apply a natural box layout model to all elements, but allowing components to change
html {
    box-sizing: border-box;
    height:     100%;
}
*, *:before, *:after {
    box-sizing: inherit;
}

// Global settings
body {
    background-color: $bg;
    background: linear-gradient(to bottom, $gray-4 0%, $bg 100%);
    color:            $base-font-color;
    font-family:      $base-font-family;
    font-size:        $base-font-pixel+px;
    font-weight:      $base-font-weight;
    letter-spacing:   $base-letter-spacing;
    line-height:      $base-line-height;
    min-height:       100%;
    padding-top:      pxToRem(25);
    position:         relative;
}

.sr-only {
    border:    0;
    clip:      rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height:    1px;
    margin:    -1px;
    overflow:  hidden;
    padding:   0;
    position:  absolute;
    width:     1px;
    word-wrap:  normal !important;
}

.random {

    &__header {
        text-align: center;
        margin-bottom: pxToRem(25);

        svg {
            height: pxToRem(125);
            width: auto;
        }
    }

    &__app {
        margin: 0 auto;
        width: 85%;
        max-width: pxToRem(450);
        padding-bottom: pxToRem(100);
    }

    &__length,
    &__pasword {
        background-color: $white;
        color: $base-font-color;
        width: 100%;
        padding: pxToRem(10);
        border: 1px solid $gray-2;
        border-radius: pxToRem(10);
        margin-bottom: pxToRem(16);
        transition: all .5s ease;

        &:focus {
            background-color: $lightBlue;
            color: $white;
        }
    }

    &__generate,
    &__copy {
        background-color: $green;
        width: 100%;
        padding: pxToRem(10);
        border: 1px solid $darkGreen;
        color: $white;
        font-weight: bold;
        cursor: pointer;
        transition: all .5s ease;
        margin-bottom: pxToRem(8);
        border-radius: pxToRem(10);

        &:hover,
        &:focus {
            background-color: $darkGreen;
            border-color: $green;
            color: $white;
        }
    }

    &__copy {
        background-color: $lightBlue;
        border-color: $blue;

        &:hover,
        &:focus {
            background-color: $blue;
            border-color: $lightBlue;
            color: $white;
        }
    }
    
    &__copy-status {
        font-size: pxToRem(12);
        color: $darkGreen;
        font-weight: bold;
    }

    &__footer {
        display: flex;
        position: absolute;
        bottom: 0;
        padding: pxToRem(10);
        left: 0;
        right: 0;
        background-color: $white;
        font-size: pxToRem(12);
        justify-content: space-between;
    }

}
